// primary
$primary: #c75d03;
$primaryLight: #e48a44;
$primaryLightest: #f7e7d9;

// secondary
$secondary: #04132e;
$secondaryDark: #818997;
$secondaryLight: #b4b8c0;
$secondaryInput: #687182;

// neutral
$white: #fff;

$grey800: #2d3135;
$grey600: #6c6f72;
$grey400: #abadae;
$grey200: #dfe0e1;
$grey100: #f0f0f0;

$background: #fafafa;
$boxShadow: #b2837b;
$boxShadowNavbar: #8c625f;

$navbarBackgroud: #fff;
$navbarBackgroudGradient: #383050;

$backgroundOverlay: #000;

// semantic
$success: #7ba900;
$successLight: #eff5e0;
$warning: #ddac00;
$warningLight: #fbf5e0;
$error: #d84e30;
$errorLight: #faeae6;
$info: #479ec3;
$infoLight: #e9f3f8;
$feedbackSuccess: #6f9800;
